<template>
  <div>
    <!-- <CRow class="justify-content-center">
      <CCol xl="5" lg="6">
        <CCard>
          <CCardBody>
              <CRow class="text-center">
                  <CCol>
                      <CButton size="lg" @click="openCreateNewCanvas = true" color="customblue2">สร้างคอร์สใหม่</CButton>&nbsp;
                      <CButton size="lg" @click="createEventModal = true" color="outline-customblue2">ใช้งานคอร์สเดิม</CButton>
                  </CCol>
              </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow> -->
        <CRow class="pt--2">
        <!-- <CCol class="" col="6">
            <CButton size="lg" @click="openCreateNewCanvas = true" color="customblue2">สร้างคอร์สใหม่</CButton>&nbsp;
            <CButton size="lg" @click="openUseOldCourseCanvas = true" color="outline-customblue2">ใช้งานคอร์สเดิม</CButton>
        </CCol> -->
        <CCol class="text-right" col="6">
          <popper
            trigger="clickToToggle"
            :options="{
              placement: 'bottom',
              modifiers: { offset: { offset: '0,0px' } }
            }">
            <div class="popper text-center" style="padding: 10px;">
              <CRow>
                <CCol col=3>
                  <b>แสดงเทอม</b>
                </CCol>
                <CCol col=9>
                  <CInputCheckbox
                    label="เทอมปัจจุบัน"
                    value="showPresentCheckBox"
                    :inline="true"
                    :checked="filterChecked.present"
                    @update:checked="checkFilter"
                  />
                  <CInputCheckbox
                    label="เทอมถัดไป"
                    value="showFutureCheckBox"
                    :inline="true"
                    :checked="filterChecked.future"
                    @update:checked="checkFilter"
                  />
                </CCol>
              </CRow>
            </div>
            <button class="btn btn-secondary btn-lg" slot="reference">
              <b>Filter<CIcon height=25 name="cil-filter"/></b>
            </button>
          </popper>
        </CCol>
    </CRow>

    <!-- <CreateMoodleModal @afterCreateEvent="callEventListAgain"/> -->
    <CreateNewCanvas :openModal="openCreateNewCanvas" @afterCloseModal="openCreateNewCanvas = false" @afterCreateCanvas="callCanvasListAgain" />
    <UseOldCourseCanvas :openModal="openUseOldCourseCanvas" @afterCloseModal="openUseOldCourseCanvas = false" @afterCreateCanvas="callCanvasListAgain" />
    <CRow>
      <CardsCanvasSandbox :canvasObject="canvasList[0]" @afterDeleteEvent="callCanvasListAgain"/>
      <CardsCanvas v-bind:key="canvas.id"  v-for='canvas in canvasList' :canvasObject="canvas" @afterDeleteEvent="callCanvasListAgain"/>
    </CRow>
    <CCard>
    </CCard>
  </div>
</template>
<script>
import CardsCanvas from './CardsCanvas'
import CardsCanvasSandbox from './CardsCanvasSandbox'
// import CreateCanvasModal from './CreateCanvasModal'
import CreateNewCanvas from './CreateNewCanvas'
import UseOldCourseCanvas from './UseOldCourseCanvas'
import CanvasApi from "@/services/CanvasApi"
import Swal from 'sweetalert2'
import Popper from 'vue-popperjs'
import 'vue-popperjs/dist/vue-popper.css'

export default {
  name: 'Canvaslist',
  components: {
    CardsCanvas,
    CardsCanvasSandbox,
    // CreateCanvasModal,
    UseOldCourseCanvas,
    CreateNewCanvas,
    'popper': Popper
  },
  data () {
    return {
      canvasListAll:[],
      canvasList:[],
      openCreateNewCanvas:false,
      openUseOldCourseCanvas:false,
      reg_Term:process.env.VUE_APP_REG_TERM,
      reg_Year:process.env.VUE_APP_REG_YEAR,
      filterChecked:{
        all:false,
        past:false,
        present:true,
        future:false
      },
      term:{
        presentTerm: "",
        futureTerm: "",
        pastYearForFuterTerm: "",
      }
    }
  },
  created() {
    this.calculateTerm()
    this.getCanvasListByUser(localStorage.getItem('email'))
    // if(localStorage.getItem('Admin') === 'admin'){
    //   console.log("Get Event: Admin active")
    //   if(this.$route.query.geteventof){
    //     if(this.$route.query.geteventof == 'all'){
    //       console.log("Get Event: Get Event All")
    //       this.getEventListALl()
    //     }else{
    //       console.log("Get Event: Get Event of:",this.$route.query.geteventof)
    //       this.getEventListByOwner(this.$route.query.geteventof)
    //     }
    //   }else{
    //     console.log("Get Event: Get Event of yourself")
    //     this.getEventListByOwner(localStorage.getItem('email'))
    //   }
    // }else{
    //   this.getEventListByOwner(localStorage.getItem('email'))
    // }
  },
  methods: {
    calculateTerm () {
      this.term.presentTerm = `${this.reg_Term}/${this.reg_Year.slice(2, 4)}`
      this.reg_Term = parseInt(this.reg_Term)
      if(this.reg_Term < 3){
          this.term.futureTerm = `${this.reg_Term+1}/${this.reg_Year.slice(2, 4)}`
          this.term.pastYearForFuterTerm = `${this.reg_Term+1}/${parseInt(this.reg_Year.slice(2, 4))-1}`
      }else{
          this.term.futureTerm = `1/${parseInt(this.reg_Year.slice(2, 4))+1}`
          this.term.pastYearForFuterTerm = `1/${parseInt(this.reg_Year.slice(2, 4))}`
      }
    },
    async getCanvasListByUser(email){
      console.log("Hi")
      let cmuitaccount_name = email.substring(0, email.lastIndexOf("@"))
      // let splitTerm = this.term.futureTerm.split("/")
      Swal.fire({
        text: 'กำลังโหลด...',
        allowOutsideClick: false
      })
      Swal.showLoading()
      let canvasList = await CanvasApi.getCanvasListByUser(cmuitaccount_name)
      if(canvasList){
        // this.canvasList = await this.filterOnlyThisTerm(canvasList, this.canvasListAll, splitTerm[0], "25"+splitTerm[1])
        this.canvasListAll = canvasList
        this.canvasList = await this.filterOnlyThisTerm(canvasList, this.reg_Term, this.reg_Year)
        if(localStorage.getItem('filterShowFuture') == 'true'){
          this.filterChecked.future = true
          let val = {target:{value:""}}
          val.target.value = "showFutureCheckBox"
          this.checkFilter (true, val)
        }
        if(localStorage.getItem('filterShowPast') == 'true'){
          this.filterChecked.past = true
        }
        console.log("getCanvasListByUser(Component):",canvasList)
        Swal.close()
      }
    },
    // async filterOnlyThisTerm (canvasListAll) {
    //   for(let i = canvasListAll.length - 1; i >= 0; i--){
    //     console.log("filterOnlyThisTerm:",canvasListAll[i])
    //     // let fullSemester = this.reg_Term+this.reg_Year.slice(2, 4)
    //     if(canvasListAll[i].sis_course_id === null){
    //       canvasListAll.splice(i, 1)
    //     }else if(canvasListAll[i].sis_course_id.slice(0, 3) !== this.reg_Term+this.reg_Year.slice(2, 4)){
    //       console.log(canvasListAll[i].sis_course_id.slice(0, 3),this.reg_Term+this.reg_Year.slice(2, 4))
    //       console.log(canvasListAll[i].sis_course_id.slice(0, 3) !== this.reg_Term+this.reg_Year.slice(2, 4))
    //       canvasListAll.splice(i, 1)
    //     }
    //   }
    //   return canvasListAll
    // },
    async filterOnlyThisTerm (canvasListAll, term, year) {
      let newCanvasList = []
      console.log("filterOnlyThisTerm:",canvasListAll)
      for(let i = canvasListAll.length - 1; i >= 0; i--){
        console.log("filterOnlyThisTerm:",canvasListAll[i].sis_course_id)
        if(canvasListAll[i].sis_course_id !== null){
          if(canvasListAll[i].sis_course_id.slice(0, 3) === term+year.slice(2, 4)){
            newCanvasList.push(canvasListAll[i])
          }
        }else{
          console.log("sis_course_id null")
        }
        
      }
      return newCanvasList
    },
    async deleteOnlyThisTerm (canvasList, term, year) {
      // let newMoodleList = []
      for(let i = canvasList.length - 1; i >= 0; i--){
        console.log("filterOnlyThisTerm:",canvasList[i])
        // let fullSemester = this.reg_Term+this.reg_Year.slice(2, 4)
        if(canvasList[i].sis_course_id.slice(0, 3) === term+year.slice(2, 4)){
          console.log(canvasList[i].sis_course_id.slice(0, 3),term+year.slice(2, 4))
          console.log(canvasList[i].sis_course_id.slice(0, 3) !== term+year.slice(2, 4))
          canvasList.splice(i, 1)
          // newMoodleList.push(moodleList[i])
        }
      }
      return canvasList
    },
    btnClicked (index) {
      this.$router.push({path: `events/${index}`})
    },
    async callCanvasListAgain() {
      console.log('call again')
      this.openCreateNewCanvas = false
      this.openUseOldCourseCanvas = false
      await this.getCanvasListByUser(localStorage.getItem('email'))
    },
    async checkFilter (event, val) {
      let canvasListAfterAppend = []
      let canvasListAfterDelete = []
      let splitTerm = []
      if(event){
        console.log(val.target.value)
        switch (val.target.value) {
          case "showFutureCheckBox":
            console.log("CanvasListAll:",this.canvasListAll)
            localStorage.setItem('filterShowFuture', true)
            splitTerm = this.term.futureTerm.split("/")
            canvasListAfterAppend = await this.filterOnlyThisTerm(this.canvasListAll, splitTerm[0], "25"+splitTerm[1])
            this.canvasList = this.canvasList.concat(canvasListAfterAppend)
            break
          case "showPresentCheckBox":
            console.log("CanvasListAll:",this.canvasListAll)
            localStorage.setItem('filterShowPresent', true)
            splitTerm = this.term.presentTerm.split("/")
            canvasListAfterAppend = await this.filterOnlyThisTerm(this.canvasListAll, splitTerm[0], "25"+splitTerm[1])
            this.canvasList = this.canvasList.concat(canvasListAfterAppend)
            break
          case "showPastCheckBox":
            
            break
          case "showAllCheckBox":
            
            break
          default:
            break
        }
      }else{
        console.log(val.target.value)
        switch (val.target.value) {
          case "showFutureCheckBox":
            console.log("CanvasList:",this.canvasList)
            localStorage.setItem('filterShowFuture', false)
            splitTerm = this.term.futureTerm.split("/");
            canvasListAfterDelete = await this.deleteOnlyThisTerm(this.canvasList, splitTerm[0], "25"+splitTerm[1])
            this.canvasList = canvasListAfterDelete
            break
          case "showPresentCheckBox":
            console.log("CanvasList:",this.canvasList)
            localStorage.setItem('filterShowPresent', false)
            splitTerm = this.term.presentTerm.split("/");
            canvasListAfterDelete = await this.deleteOnlyThisTerm(this.canvasList, splitTerm[0], "25"+splitTerm[1])
            this.canvasList = canvasListAfterDelete
            break
          case "showPastCheckBox":
            
            break
          case "showAllCheckBox":
            
            break
          default:
            break
        }
      }
    }
  }
}
</script>
